// Max Width Percentages

.mw-100  { max-width: 100%; }

// Max Width Scale

.mw1  {  max-width: 1rem; }
.mw2  {  max-width: 2rem; }
.mw3  {  max-width: 4rem; }
.mw4  {  max-width: 8rem; }
.mw5  {  max-width: 16rem; }
.mw6  {  max-width: 32rem; }
.mw7  {  max-width: 48rem; }
.mw8  {  max-width: 64rem; }
.mw9  {  max-width: 96rem; }

// Max Width String Properties

.mw-none { max-width: none; }

@media #{$breakpoint-not-small} {
  .mw-100-ns  { max-width: 100%; }

  .mw1-ns  {  max-width: 1rem; }
  .mw2-ns  {  max-width: 2rem; }
  .mw3-ns  {  max-width: 4rem; }
  .mw4-ns  {  max-width: 8rem; }
  .mw5-ns  {  max-width: 16rem; }
  .mw6-ns  {  max-width: 32rem; }
  .mw7-ns  {  max-width: 48rem; }
  .mw8-ns  {  max-width: 64rem; }
  .mw9-ns  {  max-width: 96rem; }

  .mw-none-ns { max-width: none; }
}

@media #{$breakpoint-medium} {
  .mw-100-m  { max-width: 100%; }

  .mw1-m  {  max-width: 1rem; }
  .mw2-m  {  max-width: 2rem; }
  .mw3-m  {  max-width: 4rem; }
  .mw4-m  {  max-width: 8rem; }
  .mw5-m  {  max-width: 16rem; }
  .mw6-m  {  max-width: 32rem; }
  .mw7-m  {  max-width: 48rem; }
  .mw8-m  {  max-width: 64rem; }
  .mw9-m  {  max-width: 96rem; }

  .mw-none-m { max-width: none; }
}

@media #{$breakpoint-large} {
  .mw-100-l  { max-width: 100%; }

  .mw1-l  {  max-width: 1rem; }
  .mw2-l  {  max-width: 2rem; }
  .mw3-l  {  max-width: 4rem; }
  .mw4-l  {  max-width: 8rem; }
  .mw5-l  {  max-width: 16rem; }
  .mw6-l  {  max-width: 32rem; }
  .mw7-l  {  max-width: 48rem; }
  .mw8-l  {  max-width: 64rem; }
  .mw9-l  {  max-width: 96rem; }

  .mw-none-l { max-width: none; }
}
