// Height Scale 

.h1 { height: 1rem; }
.h2 { height: 2rem; }
.h3 { height: 4rem; }
.h4 { height: 8rem; }
.h5 { height: 16rem; }

// Height Percentages - Based off of height of parent 

.h-25 {  height:  25%; }
.h-50 {  height:  50%; }
.h-75 {  height:  75%; }
.h-100 { height: 100%; }

.min-h-100 { min-height: 100%; }

// Screen Height Percentage 

.vh-25 {  height:  25vh; }
.vh-50 {  height:  50vh; }
.vh-75 {  height:  75vh; }
.vh-100 { height: 100vh; }

.min-vh-100 { min-height: 100vh; }


// String Properties 

.h-auto {     height: auto; }
.h-inherit {  height: inherit; }

@media #{$breakpoint-not-small} {
  .h1-ns {  height: 1rem; }
  .h2-ns {  height: 2rem; }
  .h3-ns {  height: 4rem; }
  .h4-ns {  height: 8rem; }
  .h5-ns {  height: 16rem; }
  .h-25-ns { height: 25%; }
  .h-50-ns { height: 50%; }
  .h-75-ns { height: 75%; }
  .h-100-ns { height: 100%; }
  .min-h-100-ns { min-height: 100%; }
  .vh-25-ns {  height:  25vh; }
  .vh-50-ns {  height:  50vh; }
  .vh-75-ns {  height:  75vh; }
  .vh-100-ns { height: 100vh; }
  .min-vh-100-ns { min-height: 100vh; }
  .h-auto-ns { height: auto; }
  .h-inherit-ns { height: inherit; }
}

@media #{$breakpoint-medium} {
  .h1-m { height: 1rem; }
  .h2-m { height: 2rem; }
  .h3-m { height: 4rem; }
  .h4-m { height: 8rem; }
  .h5-m { height: 16rem; }
  .h-25-m { height: 25%; }
  .h-50-m { height: 50%; }
  .h-75-m { height: 75%; }
  .h-100-m { height: 100%; }
  .min-h-100-m { min-height: 100%; }
  .vh-25-m {  height:  25vh; }
  .vh-50-m {  height:  50vh; }
  .vh-75-m {  height:  75vh; }
  .vh-100-m { height: 100vh; }
  .min-vh-100-m { min-height: 100vh; }
  .h-auto-m { height: auto; }
  .h-inherit-m { height: inherit; }
}

@media #{$breakpoint-large} {
  .h1-l { height: 1rem; }
  .h2-l { height: 2rem; }
  .h3-l { height: 4rem; }
  .h4-l { height: 8rem; }
  .h5-l { height: 16rem; }
  .h-25-l { height: 25%; }
  .h-50-l { height: 50%; }
  .h-75-l { height: 75%; }
  .h-100-l { height: 100%; }
  .min-h-100-l { min-height: 100%; }
  .vh-25-l {  height:  25vh; }
  .vh-50-l {  height:  50vh; }
  .vh-75-l {  height:  75vh; }
  .vh-100-l { height: 100vh; }
  .min-vh-100-l { min-height: 100vh; }
  .h-auto-l { height: auto; }
  .h-inherit-l { height: inherit; }
}
