// Measure is limited to ~66 characters
.measure {
  max-width: 30em;
}

// Measure is limited to ~80 characters
.measure-wide {
  max-width: 34em;
}

// Measure is limited to ~45 characters
.measure-narrow {
  max-width: 20em;
}

// Book paragraph style - paragraphs are indented with no vertical spacing.
.indent {
  text-indent: 1em;
  margin-top: 0;
  margin-bottom: 0;
}

// Combine this class with a width to truncate text (or just leave as is to truncate at width of containing element.

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media #{$breakpoint-not-small} {
  .measure-ns  {
    max-width: 30em;
  }
  .measure-wide-ns {
    max-width: 34em;
  }
  .measure-narrow-ns {
    max-width: 20em;
  }
  .indent-ns {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .truncate-ns {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media #{$breakpoint-medium} {
  .measure-m {
    max-width: 30em;
  }
  .measure-wide-m {
    max-width: 34em;
  }
  .measure-narrow-m {
    max-width: 20em;
  }
  .indent-m {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .truncate-m {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media #{$breakpoint-large} {
  .measure-l {
    max-width: 30em;
  }
  .measure-wide-l {
    max-width: 34em;
  }
  .measure-narrow-l {
    max-width: 20em;
  }
  .indent-l {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .truncate-l {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tr {
  text-align: right;
}